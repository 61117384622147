$saep-color: #588fcc;
$chat-width: 100%;
$message-width: 83.5%; // era 69%
$input-section-height: 7rem;
$border-radius-size: 1rem;
$border-radius-input-box: 1rem;
// $light-grey: #f8f8f8;
$white-background: #ffffff;

@mixin scrollbar() {
  &::-webkit-scrollbar {
    width: 1rem;
    // height: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 1rem;
    background-color: rgba(0, 0, 0, 0.15);
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

// :root {
//   --vh: 100%;
// }


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  @include scrollbar();
}

body {
  width: 100vw;
  height: 100dvh;
  // background-color: hsl(0, 0%, 12%);
  background-color: $white-background;
  // font-family: Arial, Helvetica, sans-serif;
  font-family: Font Awesome 5 Brands;
  // font-weight: 400;
}

whole-page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100dvh;
}

nav {
  width: 100%;
  height: clamp(4rem, 10vh, 6rem);
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  background-color: $white-background;
  // border-bottom: solid 1px #588fcc;s

  .main-logo-cont {
    margin-left: 1rem;

    img {
      height: clamp(3rem, 7vh, 4rem);
      width: auto;
    }
  }

  .buttons-cont {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-shrink: 0;

    div {
      height: 100%;
      margin-right: 1.5rem;
      // width: clamp(3rem, 5vw, 4rem);
      aspect-ratio: 1 / 1;
      border-radius: 5px;

      // box-shadow: 
      //   -3px -3px 3px 0 #aeaec0,
      //   5px 5px 5px 0 #ffffff;
      // &:hover {
      //   box-shadow: -1px 2px 10px 0px rgba(0, 0, 0, 0.15);
      //   transform: scale(0.96);
      // }
      svg {
        height: clamp(1.6rem, 5vh, 2rem);
        width: clamp(1.6rem, 5vh, 2rem);
      }
    }
  }

  .buttons-cont::before {
    content: '';
    display: block;
    width: 0.11rem;
    height: auto;
    background-color: $saep-color;
    margin-right: clamp(1.3rem, 5vh, 2rem);
  }
}

chat-section {
  flex-grow: 1;
  background-color: $white-background;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-top: 0.5rem;
}

messages-section {
  width: 100%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;

  .thinking-cont {
    align-items: start;
  }

  .pageEmptyCont {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    .introCont {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .introTitle {
        font-size: clamp(2.4rem, 5vw, 3.479rem);
      }

      .introSubTitle {
        font-size: clamp(1.3rem, 3vw, 2rem);
      }
    }

    // quadrotti a mo di tabella, se dovessero servire in futuro
    // .suggestionsCont {
    //   display: grid;
    //   grid-template-columns: repeat(2, 1fr);
    //   grid-template-rows: repeat(2, 1fr);
    //   gap: 1.5rem;

    //   .suggestionsItem {
    //     padding: 3.5rem;
    //     border-radius: $border-radius-size;
    //     box-shadow: -2px 2px 12px -2px rgba(0, 0, 0, 0.15);
    //     cursor: pointer;
    //     transition: ease .08s;
    //     text-align: center;
    //     word-wrap: break-word;

    //     &:hover {
    //       box-shadow: -1px 2px 10px 0px rgba(0, 0, 0, 0.15);
    //       transform: scale(0.96);
    //     }
    //   }
    // }

    .suggestionsCont {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;

      .suggestionsItem {
        display: flex;
        align-items: center;
        text-align: center;
        width: 15rem;
        // height: 10rem;
        padding: 2rem;
        border-radius: $border-radius-size;
        box-shadow: -2px 2px 12px -2px rgba(0, 0, 0, 0.15);
        // box-shadow: 
        //   -5px -5px 5px 0 #aeaec0,
        //   5px 5px 5px 0 #ffffff;
        cursor: pointer;
        transition: ease .08s;
        // word-wrap: break-word;

        &:hover {
          box-shadow: -1px 2px 10px 0px rgba(0, 0, 0, 0.15);
          transform: scale(0.96);
        }
      }
    }
  }

  message-cont {
    padding: 0.5rem;
    width: $chat-width;
    max-width: 54rem;
    border-radius: 0.3rem;
    margin-left: 1rem;

    display: flex;
  }

  .ai-message,
  .human-message {
    max-width: $message-width;
    white-space: normal;
    word-wrap: break-word;
    // line-height: 2;
  }

  .ai-message {
    margin-right: auto; // Allinea i messaggi AI a sinistra
    // p {
    //   color: rgb(136, 136, 136)
    // }
  }

  .human-message {
    margin-left: auto; // Allinea i messaggi umani a destra
    padding: 0.7rem;
    border-radius: $border-radius-size;
    // background-color: hsl(0, 0%, 87%);
    background-color: $saep-color;

    p {
      color: white;
    }
  }
}

input-section {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  display: grid;
  place-items: center;

  .go-to-bottom-button {
    background: transparent;
    position: fixed;
    bottom: 7rem;
    border: none;
    transform: translateY(-1rem);

    svg {
      height: 2rem;
      width: 2rem;
    }

  }

  input-box {
    height: 100%;
    width: 54rem;
    max-width: 90vw;
    background-color: hsl(0, 0%, 87%);
    border-radius: $border-radius-input-box;
    // border: 1px solid black;
    display: flex;
    flex-direction: row;

    .crediti-cont {
      cursor: default;
    }
  }

  .textarea-cont {
    height: 100%;
    flex-grow: 1;
    padding: 1rem 0 1rem 1rem;

    textarea {
      height: 3rem;
      width: 100%;
      background-color: hsl(0, 0%, 87%);
      border: none;
      resize: none;
      overflow-y: scroll;
      font-size: 1rem;

      &:focus {
        outline: none;
      }

      &::placeholder {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif;
      }
    }
  }

  .buttons {
    height: 100%;
    width: 5rem;
    flex-shrink: 0;
    border-radius: $border-radius-size;
    background-color: hsl(0, 0%, 87%);
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 0.6rem;
    margin-left: 0.5rem;
    padding-bottom: 0.5rem;

    .send-button {
      height: 1rem;
      width: 1rem;

      .size-6 {
        height: 100%;
        width: 100%;
        transform: translateY(-0.2rem) rotate(-45deg);
      }

      .size-6-stop {
        transform: translateY(-0.1rem) translateX(-0.1rem) scale(1.2);
        height: 100%;
        width: 100%;
        animation: pulse 1.5s infinite ease;
      }
    }
  }
}

footer {
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  background-color: $white-background;
  padding: 0.3rem;

  display: flex;
  align-items: center;
  justify-content: center;

  >*:not(:last-child) {
    &::after {
      content: "|";
      margin: 0 0.5rem;
    }
  }
}

dialog {
  position: fixed;
  width: 100%;
  max-width: min(450px, 95%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: $border-radius-size;
  border: none;
  justify-content: center;
  background-color: rgb(255, 255, 255);

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.58);
  }

  form {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    gap: 1.5rem;

    .titleCont {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .titleDial {
        width: 100%;
        font-weight: 500;
      }

      button {
        width: 2rem;
        height: 2rem;
        display: grid;
        place-items: center;
        border-radius: 9999px;
        border: none;
        cursor: pointer;
        background-color: transparent;
      }

      dialog form .titleCont button:hover {
        color: white;
        background-color: #588fcc;
      }

      dialog form .titleCont button svg {
        height: 1.2rem;
        width: 1.2rem;
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: rgb(200, 200, 200);
    }

    .txtDialCont {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5rem;

      .txtDial {
        font-weight: 400;
        padding-right: 1rem;
      }

      div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: end;
        gap: 1.5rem;

        .button-confirm {
          min-width: 5rem;
          cursor: pointer;
          color: white;
          border: none;
          border-radius: 9999px;
          padding: 0.7rem 1.2rem;
          box-shadow: -2px 2px 9px 0px rgba(0, 0, 0, 0.2);
          background-color: $saep-color;
          outline: none;
          font-size: 1rem;
        }
      }
    }
  }
}

message-cont {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .feedback-container {
    display: flex;
    gap: 0.5rem;
    height: 100%;
    width: 100%;

    button {
      border: none;
      background-color: white;

      svg {
        height: 1.2rem;
        width: 1.2rem;
      }
    }
  }
}

svg,
img {
  cursor: pointer;
}

.referenceBottomChat {
  transform: translateY(-3rem);
}

.size-6 {
  cursor: pointer;
  transition: stroke 0.3s ease, fill 0.3s ease;
}

.size-6.clicked {
  stroke: $saep-color;
  fill: white;
}

ul,
ol {
  padding-left: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;

  li {
    margin-bottom: 0.7rem;
  }

  li::marker {
    color: $saep-color;
  }
}

h3,
h4,
h5,
strong {
  margin-top: 0.6rem;
  color: $saep-color;
}

button:focus {
  outline: none;
}

@media (max-width: 1024px) {
  messages-section {
    message-cont {
      width: 90%;
    }
  }

  messages-section .pageEmptyCont .suggestionsCont {
    flex-direction: column;
  }
}

.invisible {
  display: none !important;
}

@keyframes pulse {
  0% {
    transform: translateY(-0.1rem) translateX(-0.1rem) scale(1.2);
    // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  50% {
    transform: translateY(-0.1rem) translateX(-0.1rem) scale(1.35);
    // box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: translateY(-0.1rem) translateX(-0.1rem) scale(1.2);
    // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}