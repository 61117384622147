* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*::-webkit-scrollbar {
  width: 1rem;
}
*::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
*::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
*::-webkit-scrollbar-corner {
  background-color: transparent;
}

body {
  width: 100vw;
  height: 100dvh;
  background-color: #ffffff;
  font-family: Font Awesome 5 Brands;
}

whole-page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100dvh;
}

nav {
  width: 100%;
  height: clamp(4rem, 10vh, 6rem);
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
}
nav .main-logo-cont {
  margin-left: 1rem;
}
nav .main-logo-cont img {
  height: clamp(3rem, 7vh, 4rem);
  width: auto;
}
nav .buttons-cont {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-shrink: 0;
}
nav .buttons-cont div {
  height: 100%;
  margin-right: 1.5rem;
  aspect-ratio: 1/1;
  border-radius: 5px;
}
nav .buttons-cont div svg {
  height: clamp(1.6rem, 5vh, 2rem);
  width: clamp(1.6rem, 5vh, 2rem);
}
nav .buttons-cont::before {
  content: "";
  display: block;
  width: 0.11rem;
  height: auto;
  background-color: #588fcc;
  margin-right: clamp(1.3rem, 5vh, 2rem);
}

chat-section {
  flex-grow: 1;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-top: 0.5rem;
}

messages-section {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
}
messages-section .thinking-cont {
  align-items: start;
}
messages-section .pageEmptyCont {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
messages-section .pageEmptyCont .introCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
messages-section .pageEmptyCont .introCont .introTitle {
  font-size: clamp(2.4rem, 5vw, 3.479rem);
}
messages-section .pageEmptyCont .introCont .introSubTitle {
  font-size: clamp(1.3rem, 3vw, 2rem);
}
messages-section .pageEmptyCont .suggestionsCont {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}
messages-section .pageEmptyCont .suggestionsCont .suggestionsItem {
  display: flex;
  align-items: center;
  text-align: center;
  width: 15rem;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: -2px 2px 12px -2px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: ease 0.08s;
}
messages-section .pageEmptyCont .suggestionsCont .suggestionsItem:hover {
  box-shadow: -1px 2px 10px 0px rgba(0, 0, 0, 0.15);
  transform: scale(0.96);
}
messages-section message-cont {
  padding: 0.5rem;
  width: 100%;
  max-width: 54rem;
  border-radius: 0.3rem;
  margin-left: 1rem;
  display: flex;
}
messages-section .ai-message,
messages-section .human-message {
  max-width: 83.5%;
  white-space: normal;
  word-wrap: break-word;
}
messages-section .ai-message {
  margin-right: auto;
}
messages-section .human-message {
  margin-left: auto;
  padding: 0.7rem;
  border-radius: 1rem;
  background-color: #588fcc;
}
messages-section .human-message p {
  color: white;
}

input-section {
  display: flex;
  width: 100%;
  flex-shrink: 0;
  display: grid;
  place-items: center;
}
input-section .go-to-bottom-button {
  background: transparent;
  position: fixed;
  bottom: 7rem;
  border: none;
  transform: translateY(-1rem);
}
input-section .go-to-bottom-button svg {
  height: 2rem;
  width: 2rem;
}
input-section input-box {
  height: 100%;
  width: 54rem;
  max-width: 90vw;
  background-color: hsl(0, 0%, 87%);
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
}
input-section input-box .crediti-cont {
  cursor: default;
}
input-section .textarea-cont {
  height: 100%;
  flex-grow: 1;
  padding: 1rem 0 1rem 1rem;
}
input-section .textarea-cont textarea {
  height: 3rem;
  width: 100%;
  background-color: hsl(0, 0%, 87%);
  border: none;
  resize: none;
  overflow-y: scroll;
  font-size: 1rem;
}
input-section .textarea-cont textarea:focus {
  outline: none;
}
input-section .textarea-cont textarea::-moz-placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
input-section .textarea-cont textarea::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
input-section .buttons {
  height: 100%;
  width: 5rem;
  flex-shrink: 0;
  border-radius: 1rem;
  background-color: hsl(0, 0%, 87%);
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 0.6rem;
  margin-left: 0.5rem;
  padding-bottom: 0.5rem;
}
input-section .buttons .send-button {
  height: 1rem;
  width: 1rem;
}
input-section .buttons .send-button .size-6 {
  height: 100%;
  width: 100%;
  transform: translateY(-0.2rem) rotate(-45deg);
}
input-section .buttons .send-button .size-6-stop {
  transform: translateY(-0.1rem) translateX(-0.1rem) scale(1.2);
  height: 100%;
  width: 100%;
  animation: pulse 1.5s infinite ease;
}

footer {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;
  background-color: #ffffff;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer > *:not(:last-child)::after {
  content: "|";
  margin: 0 0.5rem;
}

dialog {
  position: fixed;
  width: 100%;
  max-width: min(450px, 95%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1rem;
  border: none;
  justify-content: center;
  background-color: rgb(255, 255, 255);
}
dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.58);
}
dialog form {
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  gap: 1.5rem;
}
dialog form .titleCont {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
dialog form .titleCont .titleDial {
  width: 100%;
  font-weight: 500;
}
dialog form .titleCont button {
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
  border-radius: 9999px;
  border: none;
  cursor: pointer;
  background-color: transparent;
}
dialog form .titleCont dialog form .titleCont button:hover {
  color: white;
  background-color: #588fcc;
}
dialog form .titleCont dialog form .titleCont button svg {
  height: 1.2rem;
  width: 1.2rem;
}
dialog form .divider {
  width: 100%;
  height: 1px;
  background-color: rgb(200, 200, 200);
}
dialog form .txtDialCont {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
}
dialog form .txtDialCont .txtDial {
  font-weight: 400;
  padding-right: 1rem;
}
dialog form .txtDialCont div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 1.5rem;
}
dialog form .txtDialCont div .button-confirm {
  min-width: 5rem;
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 9999px;
  padding: 0.7rem 1.2rem;
  box-shadow: -2px 2px 9px 0px rgba(0, 0, 0, 0.2);
  background-color: #588fcc;
  outline: none;
  font-size: 1rem;
}

message-cont {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
message-cont .feedback-container {
  display: flex;
  gap: 0.5rem;
  height: 100%;
  width: 100%;
}
message-cont .feedback-container button {
  border: none;
  background-color: white;
}
message-cont .feedback-container button svg {
  height: 1.2rem;
  width: 1.2rem;
}

svg,
img {
  cursor: pointer;
}

.referenceBottomChat {
  transform: translateY(-3rem);
}

.size-6 {
  cursor: pointer;
  transition: stroke 0.3s ease, fill 0.3s ease;
}

.size-6.clicked {
  stroke: #588fcc;
  fill: white;
}

ul,
ol {
  padding-left: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
}
ul li,
ol li {
  margin-bottom: 0.7rem;
}
ul li::marker,
ol li::marker {
  color: #588fcc;
}

h3,
h4,
h5,
strong {
  margin-top: 0.6rem;
  color: #588fcc;
}

button:focus {
  outline: none;
}

@media (max-width: 1024px) {
  messages-section message-cont {
    width: 90%;
  }
  messages-section .pageEmptyCont .suggestionsCont {
    flex-direction: column;
  }
}
.invisible {
  display: none !important;
}

@keyframes pulse {
  0% {
    transform: translateY(-0.1rem) translateX(-0.1rem) scale(1.2);
  }
  50% {
    transform: translateY(-0.1rem) translateX(-0.1rem) scale(1.35);
  }
  100% {
    transform: translateY(-0.1rem) translateX(-0.1rem) scale(1.2);
  }
}/*# sourceMappingURL=style.css.map */